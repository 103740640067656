import React, { useState, useEffect, useContext } from 'react'
import { useParams, useLocation } from "react-router-dom";
import { Container, Alert, Row, Col, Button, FormGroup, Label, FormFeedback, FormText, Input } from 'reactstrap';
import { SettingsContext } from '../../App';

import { BURKE_BASE_API_URL, LOCAL_BURKE_BASE_API_URL } from '../../constants';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import axios from 'axios';


function Review() {
  const [theComment, setComment] = useState('');
  const [theRating, setRating] = useState('');
  const [theReviewFormSubmit, settheReviewFormSubmit] = useState(false);

  let [theService, setService] = useState('');
  let [theProviderName, setProviderName] = useState('');
  let [theCustomerName, setCustomerName] = useState('');
  let [theHash, setHashToken] = useState('');
  let [theErrorMessage, setErrorMsg] = useState(true);

  const { hashKey } = useParams();

  useEffect(() => {

    let url = BURKE_BASE_API_URL + '/lead/' + hashKey;
    axios.get(url, {}).then((response) => {
      if (response.data != 'data not found') {
        setService(response.data.service);
        setProviderName(response.data.provider_name);
        setCustomerName(response.data.customer_name);
        setHashToken(response.data.hashToken);
        setErrorMsg(false)
      } else {
        setErrorMsg('Data Not Found');
      }
    }).catch(function (error) {
    });
  });

  const __handleSubmit = (e) => {
    let review_post_url = BURKE_BASE_API_URL + '/review';
    axios.post(review_post_url, {
      comment: theComment,
      rating: theRating,
      hash_token: hashKey,
    })
      .then(function (response) {
        if (response.data.success) {
          settheReviewFormSubmit(true);
        }
      })
      .catch(function (error) {

      });
  }

  return <>
    {
      !theErrorMessage && <>
        <Container className={`page page-home mt-8 ml-5`} fluid>
          <Row className='justify-content-center'>
            <Col sm="12" md="12" lg="6">
              
              <h4 className='mt-4 mb-4'>Hello, {theCustomerName}</h4>
              <p className='mb-5'>Please share your experience about the {theService} service provided by {theProviderName}. </p>
            </Col>
          </Row>
          <Row className='justify-content-center'>
            <Col sm="12" md="12" lg="6">
              <div class="review-form">
                <FormGroup>
                  <Input onChange={(e) => setRating(e.target.value)} id="rating" name="review-ratin" type="select">
                    <option value="">-Select a rating-</option>
                    <option value="1">1 - Poor</option>
                    <option value="2">2 - Average</option>
                    <option value="3">3 - Okay</option>
                    <option value="4">4 - Satisfactory</option>
                    <option value="5">5 - Excellent </option>
                  </Input>
                </FormGroup>
                <FormGroup className='mt-4'>
                  <Input type="textarea" rows="7" max="500" placeholder="Enter your comments here (max 500 characters)" value={theComment} onChange={(e) => setComment(e.target.value)} id="comment" name="comment" />
                </FormGroup>


                {
                  theReviewFormSubmit && <Alert color="success">
                    <FontAwesomeIcon icon={faCircleCheck} /> Thank you for sharing your experience with us.
                  </Alert>
                }


                <FormGroup className='text-center'>
                  <Button className='mb-2' color="primary" onClick={e => __handleSubmit(e)} size="lg" block style={{ height: 60 }}>
                    <> Submit </>
                  </Button>
                </FormGroup>
              </div>
            </Col>
          </Row>
        </Container>
      </>
    }
    {
      theErrorMessage && <>
        <Container className={`page page-home mt-5 ml-5`} fluid>
          <Row className='justify-content-center'>
            <Col sm="12" md="12" lg="6">
              <h2 className='ml-50 justify-content-center'>404 Not Found!</h2>
            </Col>
          </Row>
        </Container>
      </>
    }
  </>

}

export default Review;