import React, { useState, useEffect } from 'react';
import {
  Container, Row, Col
} from 'reactstrap';
import {
  Link
} from "react-router-dom";

// Project Components
import Loader from '../../Components/Loader';
import RequestFailed from '../../Components/RequestFailed';
import { LOCAL_BURKE_BASE_API_URL, BURKE_BASE_API_URL } from '../../constants';


const ProviderDirectory = () => {
  const [providerData, setProviderData] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isRequestSuccess, setIsRequestSuccess] = useState(true);
  const [theRequestErrorCode, setRequestErrorCode] = useState(0);

  useEffect(() => {
    const fetchProviderData = async () => {
      try {
        const response = await fetch(`${BURKE_BASE_API_URL}/providers_list`);
        // const response = await fetch('http://127.0.0.1:8000/api/providers_directory');
        if (response.ok) {
          const data = await response.json();
          setProviderData(data.data);
        } else {
          console.error('Failed to fetch provider data');
        }
      } catch (error) {
        console.error('Error fetching provider data:', error);
      }
    };

    fetchProviderData();
  }, []);

  const HEADER = () => {
    return <>
      <Row className='page-header style1'>
        <h1>Providers Directory</h1>
      </Row>
    </>
  }

  const renderProviderList = () => {
    if (!providerData) {
      return <p>Loading...</p>;
    }

    return (
      <div>
        <Container className={`page page-provider-signup bg-gray`} fluid style={{ paddingBottom: 200 }}>
          <HEADER />
          <Container>
            <Row className={`pt-8 justify-content-center`}>
              <Col lg="6">
                {
                  isLoading ? <Loader /> : (!isRequestSuccess ? <RequestFailed errorCode={theRequestErrorCode} /> : '')
                }

                {
                  !isLoading && <>
                    {Object.entries(providerData).map(([letter, providers]) => (
                      <div key={letter} className="mt-8" style={{ borderBottom: '1px solid gray' }}>
                        <h1 className='mb-2'>{letter}</h1>
                        <div className="thelist" style={{ columnCount: 3}}>
                          {providers.map((provider, index) => (
                            <div key={index} className="mb-4">
                              {provider.slug !== null ? (
                                <p>
                                  <Link to={`/providers/${provider.slug}`} className="nav-link" activeClassName="active">
                                    {provider.business}
                                  </Link>
                                </p>
                              ) : (
                                <p>{provider.business}</p>
                              )}
                            </div>
                          ))}
                        </div>

                      </div>
                    ))}
                  </>
                }

              </Col>
            </Row>
          </Container>
        </Container>

      </div>
    );
  };

  return (
    <div>
      {renderProviderList()}
    </div>
  );
};

export default ProviderDirectory;
