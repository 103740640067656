import React, { useState, useRef, useEffect } from 'react'
import { Button, FormGroup, FormFeedback, FormText, Input, Label, InputGroup, InputGroupText, Fade, Alert } from 'reactstrap';
import { InfinitySpin } from 'react-loader-spinner';
import Autocomplete from "react-google-autocomplete";
import useFileUpload from 'react-use-file-upload';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faUpload } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { data } from 'browserslist';
import base64 from 'base-64';

import { isEmailValid } from '../../helpers';
import { BURKE_BASE_API_URL, BURKE_URGENCY_TYPES } from '../../constants';
import { LS_CUST_LAT, LS_CUST_LNG, LS_CUST_ADDRESS, LS_CUST_NAME, LS_CUST_EMAIL, LS_CUST_PHONE, LS_JOB_URGENCY, LS_JOB_BUDGET, LS_JOB_DESCRIPTION, LS_UPLOADED_MEDIA } from '../../constants';










function Sidebar(props) {

  // Misc
  let lat = '';
  let lng = '';
  let oneMb = 1000000;
  let maxFilesAllowed = 3;

  // Form
  const [theFormStep, setFormStep] = useState(1);
  const [isSubmitting, setSubmitting] = useState(false);
  const [isSubmittedSuccesfully, setSubmittedSuccesfully] = useState(false);
  const [isSubmissionFailed, setSubmissionFailed] = useState(false);
  const [theResponseMessage, setResponseMessage] = useState('');
  const [theEditMode, setEditMode] = useState(false);
  const [isUploading, setUploading] = useState(false);

  // Fields
  const [theSearchAddress, setSearchAddress] = useState('');
  const [theUrgency, setUrgency] = useState('');
  const [theBudget, setBudget] = useState('');
  const [theJobDescription, setJobDescription] = useState('');
  const [theFullName, setFullName] = useState('');
  const [theEmail, setEmail] = useState('');
  const [thePhoneNumber, setPhoneNumber] = useState('');
  const [theLat, setLat] = useState(0.0);
  const [theLng, setLng] = useState(0.0);
  const [theUploadedMedia, setUploadedMedia] = useState('');

  // Errors
  const [theSearchAddressError, setSearchAddressError] = useState(false);
  const [theUrgencyError, setUrgencyError] = useState(false);
  const [theBudgetError, setBudgetError] = useState(false);
  const [theJobDescriptionError, setJobDescriptionError] = useState(false);
  const [theFullNameError, setFullNameError] = useState(false);
  const [theEmailError, setEmailError] = useState(false);
  const [thePhoneNumberError, setPhoneNumberError] = useState(false);
  const [theFileCountError, setFileCountError] = useState(false);
  const [theFileSizeError, setFileSizeError] = useState(false);

  // File Upload
  const {
    files,
    clearAllFiles,
    handleDragDropEvent,
    createFormData,
    setFiles,
    removeFile,
  } = useFileUpload();
  const inputRef = useRef();





  // Get stuff from local storage
  useEffect(function () {
    let name = localStorage.getItem(LS_CUST_NAME);
    let email = localStorage.getItem(LS_CUST_EMAIL);
    let phone = localStorage.getItem(LS_CUST_PHONE);
    let lat = localStorage.getItem(LS_CUST_LAT) * 1;
    let lng = localStorage.getItem(LS_CUST_LNG) * 1;
    let address = localStorage.getItem(LS_CUST_ADDRESS);
    let urgency = localStorage.getItem(LS_JOB_URGENCY);
    let budget = localStorage.getItem(LS_JOB_BUDGET) * 1;
    let description = localStorage.getItem(LS_JOB_DESCRIPTION);
    let uploadedMedia = JSON.parse(localStorage.getItem(LS_UPLOADED_MEDIA));

    if (name) setFullName(name);
    if (email) setEmail(email);
    if (phone) setPhoneNumber(phone);
    if (lat) setLat(lat);
    if (lng) setLng(lng);
    if (address) setSearchAddress(address);
    if (urgency) setUrgency(urgency);
    if (budget) setBudget(budget);
    if (description) setJobDescription(description);
    if (uploadedMedia) setUploadedMedia(uploadedMedia);

  }, []);

  // Update stuff to local storage
  useEffect(function () {
    localStorage.setItem(LS_CUST_NAME, theFullName);
    localStorage.setItem(LS_CUST_EMAIL, theEmail);
    localStorage.setItem(LS_CUST_PHONE, thePhoneNumber);
    localStorage.setItem(LS_CUST_LAT, theLat);
    localStorage.setItem(LS_CUST_LNG, theLng);
    localStorage.setItem(LS_CUST_ADDRESS, theSearchAddress);
    localStorage.setItem(LS_JOB_URGENCY, theUrgency);
    localStorage.setItem(LS_JOB_BUDGET, theBudget);
    localStorage.setItem(LS_JOB_DESCRIPTION, theJobDescription);
    localStorage.setItem(LS_UPLOADED_MEDIA, JSON.stringify(theUploadedMedia));
  }, [theFullName, theEmail, thePhoneNumber, theLat, theLng, theSearchAddress, theUrgency, theBudget, theJobDescription, theUploadedMedia]);






  const __handlePlaceSelected = (place) => {
    console.log(place);
    if (typeof place.geometry != 'undefined' && typeof place.geometry.location != 'undefined') {
      lat = place.geometry.location.lat();
      lng = place.geometry.location.lng();
      setSearchAddress(place.formatted_address);
    }
  }







  const __handleUpload = (e) => {

    let url = BURKE_BASE_API_URL + '/leads/media-manager/uploads';

    let dataFiles = [];
    var reader = new FileReader();

    if (files.length) {
      setUploading(true);

      function readFile(index) {
        if (index >= files.length) {
          data = new FormData();
          data.append('files', JSON.stringify(dataFiles));
          axios.post(url, data, {
            'content-type': 'multipart/form-data',
          })
            .then(function (response) {

              setUploading(false);

              setResponseMessage(response.data.message);
              if (response.data.success) {

                let media = response.data.data.map((m, i) => {
                  if (m.hasOwnProperty('url') && m.url && m.url.split('.').pop() === 'mp4') {
                    m.thumb_url = '/assets/video.jpg';
                  } else m.thumb_url = m.url;
                  return m;
                })

                setUploadedMedia([...theUploadedMedia, ...media]);
                clearAllFiles();
              }

            })
            .catch(function (error) {
              setSubmitting(false);
              console.log(error);
            });
        }
        var file = files[index];
        reader.onload = function (e) {
          dataFiles.push(base64.encode(e.target.result));
          readFile(index + 1);
          console.log(dataFiles);
        }
        reader.readAsBinaryString(file);
      }
      readFile(0);

    }
  }




  const __handleDeleteMedia = (i) => {
    let uploadedMedia = [...theUploadedMedia];
    uploadedMedia.splice(i, 1);
    setUploadedMedia(uploadedMedia);
  }






  // const __handleStep1 = (e) => {
  //   let error = false;

  //   if (!theUrgency) { error = true; setUrgencyError(true); }
  //   if (!theBudget) { error = true; setBudgetError(true); }
  //   if (!theJobDescription) { error = true; setJobDescriptionError(true); }
  //   if (files.length > maxFilesAllowed) { error = true; setFileCountError(true); }
  //   if (totalSizeInBytes > oneMb * maxFilesAllowed) { error = true; setFileSizeError(true); }
  //   if (!error) {
  //     setFormStep(2);
  //   }

  // }






  const __handleSubmit = (e) => {
    let error = false;

    setSubmissionFailed(false);
    setResponseMessage('');

    if (!theFullName) { error = true; setFullNameError(true); }
    if (!theEmail || !isEmailValid(theEmail)) { error = true; setEmailError(true); }
    if (!thePhoneNumber) { error = true; setPhoneNumberError(true); }
    if (!theSearchAddress) { error = true; setSearchAddressError(true); }

    if (!error) {
      setSubmitting(true);

      let url = BURKE_BASE_API_URL + '/leads';

      const formData = createFormData();

      let uploadedMediaIDs = theUploadedMedia.length ? theUploadedMedia.map(m => m.media_id) : [];
      
      formData.append("full_name", theFullName);
      formData.append("email", theEmail);
      formData.append("phone", thePhoneNumber);
      formData.append("address", theSearchAddress);
      formData.append("lat", theLat);
      formData.append("lng", theLng);
      formData.append("city", "test city");
      formData.append("state", "test state");
      formData.append("zipcode", "WK4434");
      formData.append("budget", theBudget);
      formData.append("lead_urgency_type_id", theUrgency);
      formData.append("job_description", theJobDescription);
      formData.append("category_id", props.serviceID);
      formData.append("provider_id", props.provider.provider_id);
      if(uploadedMediaIDs) {
        formData.append("media_id", uploadedMediaIDs);
      }

      axios.post(url, formData, {
        'content-type': 'multipart/form-data',
      })
        .then(function (response) {
          console.log(response);

          setSubmitting(false);

          setResponseMessage(response.data.message);
          if (response.data.success) {
            setSubmittedSuccesfully(true);
            setEditMode(false);
          } else {
            setSubmissionFailed(true);
          }

        })
        .catch(function (error) {
          setSubmitting(false);
          setResponseMessage(error);
          setSubmissionFailed(true);
          console.log(error);
        });
    }
  }






  const UPLOAD_FILES = () => {
    const ListCSS = {}
    return <div className="form-container file-upload mb-3">

      {/* Provide a drop zone and an alternative button inside it to upload files. */}
      <div
        className='dropzone'
        onDragEnter={handleDragDropEvent}
        onDragOver={handleDragDropEvent}
        onDrop={(e) => {
          handleDragDropEvent(e);
          setFiles(e, 'a');
        }}
      >
        <p>Drag and drop files here</p>

        <button className='btn btn-warning' onClick={() => inputRef.current.click()}>or Click to select a file</button>

        {/* Hide the crappy looking default HTML input */}
        <input
          ref={inputRef}
          type="file"
          name="files[]"
          multiple
          style={{ display: 'none' }}
          onChange={(e) => {
            setFiles(e, 'a');
            inputRef.current.value = null;
          }}
        />
      </div>

      {/* Display the files to be uploaded */}
      <div className='files'>
        <ul>
          {files.map((file, i) => (
            <li key={i}>
              <span className='idx'>{i + 1}</span>
              <span className="name">{file.name} </span>
              <span className='right'>
                <span className='size'>{parseFloat(file.size / 1024).toFixed(2)} KB</span>
                <span className='remove' onClick={() => removeFile(file.name)}>
                  <FontAwesomeIcon icon={faTimes} />
                </span>
              </span>
            </li>
          ))}
        </ul>
      </div>

    </div>
  }



  const cssHeading = {
    background: 'rgb(207 207 207)',
    color: '#444444',
    padding: '14px 22px',
    position: 'sticky',
    margin: '-40px -20px 20px',
    top: '-40px'
  }
  const cssBottomActions = {
    // position: 'sticky',
    // bottom: '-40px',
    // background: 'rgb(250, 250, 250)',
    // marginLeft: '-20px',
    // marginRight: '-20px',
    // padding: '10px 20px',
    // boxShadow: '0 0px 25px 25px #fafafa'
    bottom: '-40px',
    background: 'rgb(250, 250, 250)',
    padding: '10px 20px',
    boxShadow: 'rgb(250, 250, 250) 0px 0px 25px 25px'
  }


  return <>
    <div className='sidebar scroll scroll-5 dynopanel'>
      <div class="project-details">
        <div className='row header'>
          <div className='col-md-12'>
            <h2 className='' style={{}}>
              {/* Contact {props.provider.business.name} */}
              Project Details{' '}
              <a className='float-enxd href' style={{ fontSize: '70%', color: '#555' }} onClick={e => { setEditMode(!theEditMode) }}>Edit</a>
            </h2>
          </div>
        </div>
        <div className={'form-group body ' + (theEditMode ? 'edit-mode' : 'not-edit-mode')} style={{ marginBottom: 50, padding: 20 }}>


          <FormGroup>
            {/* How Soon */}
            <Label for="urgency">
              How soon you need this service?
            </Label>
            {
              theEditMode && <>
                <Input invalid={theUrgencyError} value={theUrgency} onChange={e => {
                  setUrgencyError(false);
                  setUrgency(e.target.value)
                }} id="urgency" name="select" type="select">
                  {
                    BURKE_URGENCY_TYPES.map((v, i) => <option value={i + 1}>{v}</option>)
                  }
                </Input>
                <FormFeedback>  Please select how soon you want this job get done? </FormFeedback>
              </>
            }
            {
              !theEditMode && <>
                <div className="input-value">{BURKE_URGENCY_TYPES[theUrgency - 1]}</div>
              </>
            }

          </FormGroup>




          {/* Budget */}
          <FormGroup>
            <Label for="budget">
              Your Budget?
            </Label>
            {
              theEditMode && <>
                <InputGroup>
                  <InputGroupText>$</InputGroupText>
                  <Input invalid={theBudgetError} type="number" value={theBudget} onChange={e => {
                    setBudgetError(false);
                    setBudget(e.target.value)
                  }} id="budget" placeholder="Ex: 150" />
                  <FormFeedback>Please enter your budget </FormFeedback>
                </InputGroup>
              </>
            }
            {
              !theEditMode && <>
                <div className="input-value">{theBudget}</div>
              </>
            }

            {
              theBudgetError && !theEditMode && <>
                <FormFeedback className="d-block mt-minus-25"> Please enter your budget </FormFeedback>
              </>
            }
          </FormGroup>


          <FormGroup>
            <Label for="description">
              Job Description
            </Label>
            {
              theEditMode && <>
                <Input id="jobDescription" invalid={theJobDescriptionError} value={theJobDescription} onChange={e => {
                  setJobDescriptionError(false);
                  setJobDescription(e.target.value)
                }} name="text" type="textarea" placeholder='Please explain more about the job' />
                <FormFeedback>Please provide details about the job</FormFeedback>
              </>
            }
            {
              !theEditMode && <>
                <div className="input-value">{theJobDescription}</div>
              </>
            }
            {
              theJobDescriptionError && !theEditMode && <>
                <FormFeedback className="d-block mt-minus-25">Please provide details about the job</FormFeedback>
              </>
            }
          </FormGroup>

          {/* <FormGroup>
                  <Button color="primary" size="lg" block onClick={e => __handleStep1(e)}>Next</Button>
                </FormGroup> */}





          {/* <p className='mt-6'><strong>Please provide your contact information</strong></p> */}

          <FormGroup>
            <Label for="full_name">Your Name</Label>
            {
              theEditMode && <>
                <Input invalid={theFullNameError} value={theFullName} onChange={e => {
                  setFullNameError(false);
                  setFullName(e.target.value)
                }} id="full_name" />
                <FormFeedback>Please enter your full name</FormFeedback>
              </>
            }
            {
              !theEditMode && <>
                <div className="input-value">{theFullName}</div>
              </>
            }
            {
              theFullNameError && !theEditMode && <>
                <FormFeedback className="d-block mt-minus-25">Please enter your full name</FormFeedback>
              </>
            }
          </FormGroup>


          <FormGroup>
            <Label for="email">Your Email</Label>
            {
              theEditMode && <>
                <Input invalid={theEmailError} type="email" value={theEmail} onChange={e => {
                  setEmailError(false);
                  setEmail(e.target.value)
                }} id="email" />
                <FormFeedback> A valid email address is required </FormFeedback>
                <FormText>This is where you will get job updates</FormText>
              </>
            }
            {
              !theEditMode && <>
                <div className="input-value">{theEmail}</div>
              </>
            }
            {
              theEmailError && !theEditMode &&<>
              <FormFeedback className="d-block mt-minus-25"> A valid email address is required </FormFeedback>
              </>
            }
          </FormGroup>


          <FormGroup>
            <Label for="phone">Your Phone Number</Label>
            {
              theEditMode && <>
                <Input invalid={thePhoneNumberError} value={thePhoneNumber} onChange={e => {
                  setPhoneNumberError(false);
                  setPhoneNumber(e.target.value)
                }} id="phone" />
                <FormFeedback> A valid phone number is required </FormFeedback>
                <FormText>You may receive call or text messages on this number.</FormText>
              </>
            }
            {
              !theEditMode && <>
                <div className="input-value">{thePhoneNumber}</div>
              </>
            }
            {
              thePhoneNumberError && !theEditMode && <>
                <FormFeedback className="d-block mt-minus-25"> A valid phone number is required </FormFeedback>
              </>
            }
          </FormGroup>

          <FormGroup>
            <Label for="address">
              Your Address
            </Label>
            {
              theEditMode && <>
                {/* Google Project for the API Key: https://console.cloud.google.com/welcome?project=ltc-austin */}
                <Autocomplete invalid={theSearchAddressError} id="address" value={theSearchAddress} onChange={e => {
                  setSearchAddressError(false);
                  setSearchAddress(e.target.value)
                }} onPlaceSelected={(place) => __handlePlaceSelected(place)} autoComplete="off" options={{ types: ['address'] }} className="form-control" placeholder='Start typing to lookup your address' apiKey={"AIzaSyCdKtt0RUO6N_jUszQ2frNNgDyd95weHrI"} />
                <FormFeedback> Please select your address. </FormFeedback>
              </>
            }
            {
              !theEditMode && <>
                <div className="input-value">{theSearchAddress}</div>
              </>
            }
            {
              theSearchAddressError && !theEditMode && <>
                <FormFeedback className="d-block mt-minus-25"> Please select your address. </FormFeedback>
              </>
            }
          </FormGroup>


          <FormGroup>
            <Label for="budget">
              Please attach upto {maxFilesAllowed} files
            </Label>
            <UPLOAD_FILES />
            {(theFileCountError || theFileSizeError) ? <div class="invalid-feedback" style={{ display: 'block' }}>You can only select upto {maxFilesAllowed} files. Maximum filesize {maxFilesAllowed} MB. </div> : ''}
          </FormGroup>




          {
            files.length > 0 && <Button outline className='mb-2' color="primary" size="sm" block onClick={e => __handleUpload(e)} style={{ height: 40 }}>
              {isUploading ? <span style={{ marginLeft: "-50px", display: "inline-block" }}><InfinitySpin ariaLabel="loading" width='80' color={isUploading ? '#0a58ca' : '#fff'} /></span> : <> Upload selected files <FontAwesomeIcon icon={faUpload} /></>}
            </Button>
          }




          <div className='uploaded-media'>
            {
              theUploadedMedia && theUploadedMedia.length >= 0 && theUploadedMedia.map((media, i) => <div className={'media media-' + media.media_id + ''}>
                <a href={media.url} target="_blank"><img src={media.thumb_url} width="80" height="80" /></a>
                <a href={() => false} onClick={(e) => __handleDeleteMedia(i)} className="delete" ><FontAwesomeIcon icon={faTimes} /></a>
              </div>)
            }
          </div>
          {
            files.length > 0 && <>
              <small>Please upload select files to continue</small>
            </>
          }

        </div>
        <div class="actions" style={cssBottomActions}>
          {
            isSubmittedSuccesfully && <>
              <Fade>
                <Alert color="success">{theResponseMessage}</Alert>
              </Fade>
            </>
          }
          {
            isSubmissionFailed && <>
              <Fade><Alert color="danger">{theResponseMessage}</Alert></Fade>
            </>
          }
          <FormGroup>
            {!isSubmittedSuccesfully && <>
              <Button disabled={files.length > 0} color="primary" size="lg" block onClick={e => __handleSubmit(e)} style={{ height: 60 }}>
                {isSubmitting ? <span style={{ marginLeft: "-50px", display: "inline-block" }}><InfinitySpin ariaLabel="loading" width='80' color="#fff" /></span> : 'Request Estimate'}
              </Button>
              {/* <Button className="mt-1" outline color="secondary" size="md" block onClick={e => { setFormStep(1) }}>Back</Button> */}
            </>
            }
          </FormGroup>
        </div>


      </div >
    </div >
  </>
}
export default Sidebar;